import { Flex, chakra } from '@chakra-ui/react';
import { Banner, setWalletAddresses } from '@hypelab/sdk-react';
import Script from 'next/script';
import React from 'react';

import useAccount from 'lib/web3/useAccount';

import { hypeInit } from './hypeBannerScript';

const DESKTOP_BANNER_SLUG = 'a6d6740b05';
const MOBILE_BANNER_SLUG = 'b12fa24600';
const MOBILE_BANNER_SLUG_OTHER = '193c895c57';
const BIGGER_BANNER_SLUG_OTHER = 'c9585272d6';
const NATIVE_SLUG_OTHER = '108ae1f329';


const HypeBanner = ({ className }: { className?: string }) => {
  const { address } = useAccount();

  React.useEffect(() => {
    if (address) {
      setWalletAddresses([ address ]);
    }
  }, [ address ]);

  return (
    <>
      <Script
        id="hypelab"
        strategy="beforeInteractive"
      >{ hypeInit }</Script>
      {/* <Flex className={ className } h="90px" display={{ base: 'none', sm:'none', md: 'flex', lg: 'none' }}>
        <Banner placement={ DESKTOP_BANNER_SLUG }/>
      </Flex>
      <Flex className={ className } h="50px" display={{ base: 'none', sm:'flex', md: 'none', lg: 'none' }}>
        <Banner placement={ MOBILE_BANNER_SLUG }/>
      </Flex> */}
      <Flex className={ className } h="100px" display={{ base: 'flex'}}>
        <Banner placement={ MOBILE_BANNER_SLUG_OTHER }/>
      </Flex>
      {/* <Flex className={ className } h="250px" display={{ base: 'flex', sm:'none', md: 'none', lg: 'none' }}>
        <Banner placement={ BIGGER_BANNER_SLUG_OTHER }/>
      </Flex>
      <Flex> */}
        {/* <Native className={ className } placement={NATIVE_SLUG_OTHER} display={{ base: 'none', sm:'none', md: 'none', lg: 'none', xl: 'flex'}}>
            <div style={{ display: 'flex', width: '100%', height: '150px' }}>
              <a data-ref="ctaLink" href="/" target="_blank" rel="noreferrer">
                <div data-ref="mediaContent"></div>
              </a>
            </div>
        </Native> */}
      {/* </Flex> */}

    </>
  );
};

export default chakra(HypeBanner);
